import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {NumberFormatUtils} from '@/app/utilities/numberFormatUtils';
import {TranslationService} from '@/app/services/translation.service';

@Injectable({
    providedIn: 'root',
})
@Pipe({
    name: 'opNumber',
})
export class OpNumberPipe implements PipeTransform {

    /**
     * transforms a number according to OP standards.
     *
     * @param value
     * @param currencySymbol
     * @param monetary
     * @param percentage
     * @param decimals
     */
    transform(value: number | string, currencySymbol: string = '', monetary?: boolean, percentage?: boolean, decimals?: number): string {
        if (value === null || value === undefined) {
            value = 0;
        }

        const isMonetary = currencySymbol !== '' || monetary;
        if (currencySymbol === '' && isMonetary) {
            currencySymbol = TranslationService.translate('monetarySymbol');
        }

        const formattedNumber = NumberFormatUtils.formatNumber(value, isMonetary, decimals);
        if (percentage) {
            return formattedNumber + '%';
        } else {
            return currencySymbol + formattedNumber;
        }
    }

}
