export const environment = {
    production: true,
    base_url: 'https://my.objectiveplatform.com/',
    api_url: 'https://my.objectiveplatform.com/api/',
    cognito_user_pool_id: 'eu-central-1_HvVDpbOpD',
    cognito_client_id: '5f16p5hqsl93r8cgareng6k8qg',
    cognito_region: 'eu-central-1',
    ga_id: 'UA-59636510-5',
    gtm_id: 'GTM-NXFK86T',
    sentry_dsn: 'https://07037abebf52ec913f944353fb79f3a7@sentry-prod.objectiveplatform.com/5',
    // replaced by bash script before deploy on ACC and PROD. not used on DEV and TEST
    sentryReleaseVersion: '${SENTRY_RELEASE_VERSION}',
    enableTracking: true,
    mixpanelToken: 'ce6002eee07363ceaf50d1dab5dbd084',
    data_mapping_url: 'https://data-mapping.objectiveplatform.com',
    streamlit_url: 'https://streamlit-examples.objectiveplatform.com',
    tinymce_key: 'c281mji3gmyvyuzz0ahhmprc9znh26f1je9n3b50qvvts1cx',
    env: 'production',
};
