import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {BreadCrumb, RouteInfo} from '../entities/BreadCrumb';
import {ChildActivationEnd, Router} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {

    routes = [];
    routesSubject = new BehaviorSubject<any>(null);
    currentRouteSubject = new BehaviorSubject<number>(null);
    breadcrumbsSubject = new Subject<BreadCrumb[]>();

    constructor(private router: Router) {
        this.router.events.subscribe(async routerEvent => {
            if (routerEvent instanceof ChildActivationEnd) {
                if (routerEvent.snapshot.routeConfig != null) {
                    (routerEvent.snapshot.routeConfig as any)._loadedRoutes?.forEach((child) => {
                        if (this.routes.filter(route => route.id === child.data?.id).length < 1) {
                            this.buildAndPushRoutes(child, routerEvent.snapshot.routeConfig.path);
                        }
                    });
                    this.routesSubject.next(this.routes);
                }
            }
        });
    }

    public getRoutesObservable() {
        return this.routesSubject.asObservable();
    }

    public getCurrentRouteObservable() {
        return this.currentRouteSubject.asObservable();
    }

    public getBreadcrumbsObservable() {
        return this.breadcrumbsSubject.asObservable();
    }

    public setBreadcrumbs(breadcrumbs: BreadCrumb[]) {
        this.breadcrumbsSubject.next(breadcrumbs);
    }

    public setCurrentRouteId(routeId: number) {
        this.currentRouteSubject.next(routeId);
    }

    public setBreadcrumbTitle(routeId: number, title: string) {
        const route = this.routes.find(obj => {
            return obj.id === routeId;
        });
        if (route) {
            route.breadcrumb.title = title;
        }
        this.routesSubject.next(this.routes);
    }

    public setBreadcrumbPath(routeId: number, path: string) {
        const route = this.routes.find(obj => {
            return obj.id === routeId;
        });
        if (route) {
            route.path = path;
        }
        this.routesSubject.next(this.routes);
    }

    private buildAndPushRoutes(child, parentPath) {
        this.routes.push(new RouteInfo(child.data?.id, parentPath + '/' + child.path, child.data?.breadcrumb));
    }
}
