export class NumberFormatUtils {

    static formatNumber(value: number | string, isCurrency: boolean = false, decimals?: number): string {
        const numericValue: number = typeof value === 'string' ? parseFloat(value) : value;
        if (isNaN(numericValue)) {
            return value + '';
        }

        const absValue = Math.abs(numericValue);
        const sign = numericValue < 0 ? '-' : '';

        if (absValue >= 1000) {
            value = absValue.toFixed(decimals === undefined ? (isCurrency ? 2 : 0) : decimals);
            value = this.addThousandsSeparator(value);
        } else if (absValue >= 1) {
            value = absValue.toFixed(decimals === undefined ? 2 : decimals);
        } else {
            value = this.removeTrailingZeros(absValue.toFixed(decimals === undefined ? 4 : decimals), decimals);
        }
        return sign + value;
    }

    static removeTrailingZeros(number, minDecimals = 2) {
        let numberString = number.toString();

        if (numberString.includes('.')) {
            let decimalPart = numberString.split('.')[1];
            let trimmedDecimalPart = decimalPart.substring(0, minDecimals) + decimalPart.substring(minDecimals).replace(/0+$/, '');
            numberString = numberString.split('.')[0] + '.' + trimmedDecimalPart;
        }

        return numberString;
    }

    static addThousandsSeparator(value) {
        const parts = value.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }

}