import {ExternalPage} from '../entities/externalPage';
import {environment} from '../../environments/environment';

export class ExternalPagesUtils {
    static datasourceAdminUrl = `api/streamlit/root?streamlitUrl=${environment.data_mapping_url}/datasource/admin_dashboard/`;
    static mappingTypeAdminUrl = `api/streamlit/root?streamlitUrl=${environment.data_mapping_url}/mapping/type_admin/`;
    static approveRawDataUrl = `api/streamlit/root?streamlitUrl=${environment.data_mapping_url}/mapping/rawdata/?mappingflowid=<MAPPING_FLOW_ID>`;
    static approveMappedDataUrl = `api/streamlit/root?streamlitUrl=${environment.data_mapping_url}/mapping/viewer/?mappingflowid=<MAPPING_FLOW_ID>`;
    static manageMappingRulesUrl = `api/streamlit/root?streamlitUrl=${environment.data_mapping_url}/mapping/rules_manager/?mappingflowid=<MAPPING_FLOW_ID>`;
    static mappingFlowAdminUrl = `api/streamlit/root?streamlitUrl=${environment.data_mapping_url}/mapping/flow_admin/`;
    static modelValidationUrl = `api/streamlit/root?streamlitUrl=${environment.streamlit_url}/model-validation/?model_version=<MODEL_VERSION>`;

    static APPROVE_RAW_DATA_URL = 'approve-raw-data';
    static MANAGE_MAPPING_RULES = 'manage-mapping-rules';
    static APPROVE_MAPPED_DATA_URL = 'approve-mapped-data';
    static MODEL_VALIDATION = 'model-configuration-validation';

    /**
     * Admins and Super admins should be able to access
     * three external pages by default:
     * - Datasource Admin
     * - Mapping Type Admin
     * - Mapping Flow Admin
     * - Approve Raw Data
     */
    public static getDefaultExternalPagesForAdmin() {
        // Datasource admin page
        const dataSourceAdminPage = new ExternalPage();
        dataSourceAdminPage.name = 'Datasource Admin';
        dataSourceAdminPage.underMenuItem = 'Admin';
        dataSourceAdminPage.url = environment.base_url + ExternalPagesUtils.datasourceAdminUrl;

        // Mapping Type admin page
        const mappingTypeAdminPage = new ExternalPage();
        mappingTypeAdminPage.name = 'Mapping Type Admin';
        mappingTypeAdminPage.underMenuItem = 'Admin';
        mappingTypeAdminPage.url = environment.base_url + ExternalPagesUtils.mappingTypeAdminUrl;

        // Mapping Flow admin page
        const mappingFlowAdminPage = new ExternalPage();
        mappingFlowAdminPage.name = 'Mapping Flow Admin';
        mappingFlowAdminPage.underMenuItem = 'Admin';
        mappingFlowAdminPage.url = environment.base_url + ExternalPagesUtils.mappingFlowAdminUrl;

        // Approve Raw Data page
        const approveRawDataPage = new ExternalPage();
        approveRawDataPage.name = ExternalPagesUtils.APPROVE_RAW_DATA_URL;
        approveRawDataPage.url = environment.base_url + ExternalPagesUtils.approveRawDataUrl;

        // Approve Mapped Data page
        const approveMappedDataPage = new ExternalPage();
        approveMappedDataPage.name = ExternalPagesUtils.APPROVE_MAPPED_DATA_URL;
        approveMappedDataPage.url = environment.base_url + ExternalPagesUtils.approveMappedDataUrl;

        // Manage Mapping Rules page
        const manageMappingRulesPage = new ExternalPage();
        manageMappingRulesPage.name = ExternalPagesUtils.MANAGE_MAPPING_RULES;
        manageMappingRulesPage.url = environment.base_url + ExternalPagesUtils.manageMappingRulesUrl;

        // Model Validation page
        const modelValidationPage = new ExternalPage();
        modelValidationPage.name = ExternalPagesUtils.MODEL_VALIDATION;
        modelValidationPage.url = environment.base_url + ExternalPagesUtils.modelValidationUrl;

        return [dataSourceAdminPage, mappingTypeAdminPage, mappingFlowAdminPage, approveRawDataPage, approveMappedDataPage, manageMappingRulesPage, modelValidationPage];
    }

}
